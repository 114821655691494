import { StyledComponentPropsWithRef } from 'styled-components';
import { ComponentPropsWithRef } from 'react';
import ChatRoomImg from '../assets/pngs/chat-room.png';

export default function ChatRoomIcon(
  props: StyledComponentPropsWithRef<'img'>
) {
  return <img src={ChatRoomImg} alt="chat room" {...props} />;
}

export function ChatRoomIcon2(props: ComponentPropsWithRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M8.33329 12.5L5.77058 15.0947C5.41311 15.4566 5.23438 15.6376 5.08075 15.6504C4.94747 15.6614 4.81697 15.6079 4.72992 15.5063C4.62959 15.3893 4.62959 15.1349 4.62959 14.6262V13.3263C4.62959 12.8699 4.25585 12.5397 3.8043 12.4736V12.4736C2.71141 12.3135 1.85311 11.4552 1.69302 10.3623C1.66663 10.1821 1.66663 9.96706 1.66663 9.537V5.66663C1.66663 4.26649 1.66663 3.56643 1.93911 3.03165C2.17879 2.56124 2.56124 2.17879 3.03165 1.93911C3.56643 1.66663 4.26649 1.66663 5.66663 1.66663H11.8333C13.2334 1.66663 13.9335 1.66663 14.4683 1.93911C14.9387 2.17879 15.3211 2.56124 15.5608 3.03165C15.8333 3.56643 15.8333 4.26649 15.8333 5.66663V9.16663M15.8333 18.3333L14.0196 17.0724C13.7647 16.8951 13.6372 16.8065 13.4985 16.7437C13.3753 16.6879 13.2459 16.6473 13.1129 16.6228C12.9632 16.5952 12.8079 16.5952 12.4974 16.5952H11C10.0665 16.5952 9.59983 16.5952 9.24331 16.4135C8.92971 16.2538 8.67474 15.9988 8.51495 15.6852C8.33329 15.3287 8.33329 14.862 8.33329 13.9285V11.8333C8.33329 10.8999 8.33329 10.4332 8.51495 10.0766C8.67474 9.76304 8.92971 9.50807 9.24331 9.34828C9.59983 9.16663 10.0665 9.16663 11 9.16663H15.6666C16.6 9.16663 17.0668 9.16663 17.4233 9.34828C17.7369 9.50807 17.9918 9.76304 18.1516 10.0766C18.3333 10.4332 18.3333 10.8999 18.3333 11.8333V14.0952C18.3333 14.8718 18.3333 15.2601 18.2064 15.5663C18.0373 15.9747 17.7128 16.2992 17.3044 16.4683C16.9981 16.5952 16.6099 16.5952 15.8333 16.5952V18.3333Z"
        stroke="#718096"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
