import { ComponentPropsWithRef } from 'react';

export default function EmailIcon(props: ComponentPropsWithRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M17.4579 4.24915H2.54222C2.22462 4.24915 1.96716 4.50661 1.96716 4.8242V15.1758C1.96716 15.4934 2.22462 15.7508 2.54222 15.7508H17.4579C17.7755 15.7508 18.033 15.4933 18.033 15.1758V4.8242C18.033 4.50661 17.7755 4.24915 17.4579 4.24915ZM15.7863 5.39926L10.0001 9.91504L4.21376 5.39926H15.7863ZM3.11728 14.6007V6.00247L9.64625 11.0979C9.74729 11.1768 9.87184 11.2196 10.0001 11.2196C10.1283 11.2196 10.2528 11.1768 10.3539 11.0979L16.8828 6.00249V14.6007H3.11728Z"
        fill="#718096"
      />
    </svg>
  );
}
