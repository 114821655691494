import { StyledComponentPropsWithRef } from 'styled-components';
import TwitterLineImg from '../assets/platform/pngs/twitter.png';

export function Twitter() {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.858 5.96016C20.1752 6.26953 19.4322 6.49219 18.6669 6.57891C19.4568 6.08438 20.057 5.30391 20.3403 4.37578C19.6107 4.83281 18.7851 5.16797 17.9261 5.34141C17.23 4.56094 16.2415 4.07812 15.146 4.07812C13.0374 4.07812 11.3417 5.87344 11.3417 8.07656C11.3417 8.38594 11.3774 8.69531 11.4354 8.99297C8.27819 8.81953 5.46235 7.23516 3.59034 4.80937C3.26011 5.40469 3.07269 6.08437 3.07269 6.82734C3.07269 8.21484 3.74429 9.43828 4.76844 10.1578C4.14369 10.132 3.55464 9.94688 3.04814 9.64922V9.69844C3.04814 11.6414 4.35565 13.2516 6.09826 13.6219C5.77919 13.7086 5.43781 13.7578 5.09643 13.7578C4.84876 13.7578 4.61448 13.732 4.37797 13.6969C4.85992 15.2813 6.26337 16.432 7.93458 16.4695C6.62707 17.5453 4.98933 18.1781 3.21102 18.1781C2.89195 18.1781 2.59743 18.1664 2.29175 18.1289C3.97857 19.2656 5.98 19.9219 8.13539 19.9219C15.1326 19.9219 18.9614 13.8328 18.9614 8.54766C18.9614 8.37422 18.9614 8.20078 18.9503 8.02734C19.691 7.45781 20.3403 6.75234 20.858 5.96016Z"
        fill="white"
      />
    </svg>
  );
}
export function TwitterLine(props: StyledComponentPropsWithRef<'img'>) {
  return <img src={TwitterLineImg} alt="twitter" {...props} />;
}
