export default function MessageIcon({
  fill,
  stroke,
}: {
  fill?: string;
  stroke?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={fill || 'none'}
    >
      <path
        d="M1 12.5968V2.34148C1 1.6006 1.59695 1 2.33333 1H11.6667C12.4031 1 13 1.6006 13 2.34148V9.04888C13 9.78978 12.4031 10.3904 11.6667 10.3904H4.3075C3.90245 10.3904 3.51937 10.5756 3.26634 10.8938L1.71235 12.8482C1.47614 13.1452 1 12.9772 1 12.5968Z"
        stroke={stroke || 'white'}
      />
    </svg>
  );
}

export function MessageIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <g clipPath="url(#clip0_343_5772)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.555664 5.86192C0.563531 6.50518 0.844902 7.11922 1.33787 7.56888C1.83083 8.01854 2.495 8.26695 3.18424 8.25955H3.53979L4.59376 9.28592C4.70112 9.38743 4.8476 9.44455 5.00043 9.44455C5.15325 9.44455 5.29973 9.38743 5.40709 9.28592L6.46043 8.25955H6.81598C7.50525 8.26695 8.16938 8.01854 8.66233 7.56888C9.15528 7.11922 9.43668 6.50518 9.44455 5.86192V2.95227C9.42747 1.61315 8.25078 0.540357 6.81598 0.555829H3.18424C1.74945 0.540357 0.572743 1.61315 0.555664 2.95227V5.86192Z"
          stroke="#718096"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.22228 2.77783C2.97682 2.77783 2.77783 2.98511 2.77783 3.2408C2.77783 3.49649 2.97682 3.70377 3.22228 3.70377V2.77783ZM6.77783 3.70377C7.02328 3.70377 7.22228 3.49649 7.22228 3.2408C7.22228 2.98511 7.02328 2.77783 6.77783 2.77783V3.70377ZM3.22228 4.62968C2.97682 4.62968 2.77783 4.83696 2.77783 5.09264C2.77783 5.34839 2.97682 5.55561 3.22228 5.55561V4.62968ZM6.77783 5.55561C7.02328 5.55561 7.22228 5.34839 7.22228 5.09264C7.22228 4.83696 7.02328 4.62968 6.77783 4.62968V5.55561ZM3.22228 3.70377H6.77783V2.77783H3.22228V3.70377ZM3.22228 5.55561H6.77783V4.62968H3.22228V5.55561Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_343_5772">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MessageIcon2Color() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <g clipPath="url(#clip0_343_5778)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.555664 5.86192C0.563531 6.50518 0.844902 7.11922 1.33787 7.56888C1.83083 8.01854 2.495 8.26695 3.18424 8.25955H3.53979L4.59376 9.28592C4.70112 9.38743 4.8476 9.44455 5.00043 9.44455C5.15325 9.44455 5.29973 9.38743 5.40709 9.28592L6.46043 8.25955H6.81598C7.50525 8.26695 8.16938 8.01854 8.66233 7.56888C9.15528 7.11922 9.43668 6.50518 9.44455 5.86192V2.95227C9.42747 1.61315 8.25078 0.540357 6.81598 0.555829H3.18424C1.74945 0.540357 0.572743 1.61315 0.555664 2.95227V5.86192Z"
          stroke="url(#paint0_linear_343_5778)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.22228 2.77783C2.97682 2.77783 2.77783 2.98511 2.77783 3.2408C2.77783 3.49649 2.97682 3.70377 3.22228 3.70377V2.77783ZM6.77783 3.70377C7.02328 3.70377 7.22228 3.49649 7.22228 3.2408C7.22228 2.98511 7.02328 2.77783 6.77783 2.77783V3.70377ZM3.22228 4.62968C2.97682 4.62968 2.77783 4.83696 2.77783 5.09264C2.77783 5.34839 2.97682 5.55561 3.22228 5.55561V4.62968ZM6.77783 5.55561C7.02328 5.55561 7.22228 5.34839 7.22228 5.09264C7.22228 4.83696 7.02328 4.62968 6.77783 4.62968V5.55561ZM3.22228 3.70377H6.77783V2.77783H3.22228V3.70377ZM3.22228 5.55561H6.77783V4.62968H3.22228V5.55561Z"
          fill="url(#paint1_linear_343_5778)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_343_5778"
          x1="5.00011"
          y1="0.555664"
          x2="5.00011"
          y2="9.44455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD62FF" />
          <stop offset="1" stopColor="#62AAFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_343_5778"
          x1="5.00005"
          y1="2.77783"
          x2="5.00005"
          y2="5.55561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD62FF" />
          <stop offset="1" stopColor="#62AAFF" />
        </linearGradient>
        <clipPath id="clip0_343_5778">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
