export function Copy() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6666 5.33337V3.46671C10.6666 2.71997 10.6666 2.3466 10.5213 2.06139C10.3934 1.8105 10.1895 1.60653 9.93857 1.4787C9.65336 1.33337 9.27999 1.33337 8.53325 1.33337H3.46659C2.71985 1.33337 2.34648 1.33337 2.06126 1.4787C1.81038 1.60653 1.60641 1.8105 1.47858 2.06139C1.33325 2.3466 1.33325 2.71997 1.33325 3.46671V8.53337C1.33325 9.28011 1.33325 9.65348 1.47858 9.93869C1.60641 10.1896 1.81038 10.3936 2.06126 10.5214C2.34648 10.6667 2.71985 10.6667 3.46659 10.6667H5.33325M7.46659 14.6667H12.5333C13.28 14.6667 13.6534 14.6667 13.9386 14.5214C14.1895 14.3936 14.3934 14.1896 14.5213 13.9387C14.6666 13.6535 14.6666 13.2801 14.6666 12.5334V7.46671C14.6666 6.71997 14.6666 6.3466 14.5213 6.06139C14.3934 5.8105 14.1895 5.60653 13.9386 5.4787C13.6534 5.33337 13.28 5.33337 12.5333 5.33337H7.46659C6.71985 5.33337 6.34648 5.33337 6.06126 5.4787C5.81038 5.60653 5.60641 5.8105 5.47858 6.06139C5.33325 6.3466 5.33325 6.71997 5.33325 7.46671V12.5334C5.33325 13.2801 5.33325 13.6535 5.47858 13.9387C5.60641 14.1896 5.81038 14.3936 6.06126 14.5214C6.34648 14.6667 6.71985 14.6667 7.46659 14.6667Z"
        stroke="#718096"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Copy2() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 2.5H12.1667C14.0335 2.5 14.9669 2.5 15.68 2.86331C16.3072 3.18289 16.8171 3.69282 17.1367 4.32003C17.5 5.03307 17.5 5.96649 17.5 7.83333V13.75M5.16667 17.5H11.9167C12.8501 17.5 13.3168 17.5 13.6733 17.3183C13.9869 17.1586 14.2419 16.9036 14.4017 16.59C14.5833 16.2335 14.5833 15.7668 14.5833 14.8333V8.08333C14.5833 7.14991 14.5833 6.6832 14.4017 6.32668C14.2419 6.01308 13.9869 5.75811 13.6733 5.59832C13.3168 5.41667 12.8501 5.41667 11.9167 5.41667H5.16667C4.23325 5.41667 3.76654 5.41667 3.41002 5.59832C3.09641 5.75811 2.84144 6.01308 2.68166 6.32668C2.5 6.6832 2.5 7.14991 2.5 8.08333V14.8333C2.5 15.7668 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1586 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5Z"
        stroke="#718096"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
