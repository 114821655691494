import { ComponentPropsWithRef } from 'react';

export default function SocialAccountIcon(props: ComponentPropsWithRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M12.2653 11.2234C13.6637 10.4243 14.6626 8.8927 14.6626 7.16134C14.6626 4.56431 12.5982 2.5 10.0012 2.5C7.40418 2.5 5.33987 4.56431 5.33987 7.16134C5.33987 8.8927 6.27214 10.4243 7.73713 11.2234C5.87259 11.9559 4.47419 13.6206 4.00806 15.6184C3.94147 15.9513 4.14124 16.3508 4.54078 16.4174H4.67396C5.00692 16.4174 5.27328 16.2177 5.33987 15.8847C5.806 13.6872 7.73713 12.089 10.0012 12.089C12.2653 12.089 14.1964 13.6872 14.6626 15.8847C14.7291 16.2177 15.0621 16.484 15.4616 16.4174C15.7946 16.3508 16.061 16.0179 15.9944 15.6184C15.5282 13.6206 14.1298 11.9559 12.2653 11.2234ZM6.67168 7.16134C6.67168 5.29681 8.13667 3.83181 10.0012 3.83181C11.8657 3.83181 13.3307 5.29681 13.3307 7.16134C13.3307 9.02588 11.8657 10.4909 10.0012 10.4909C8.13667 10.4909 6.67168 9.02588 6.67168 7.16134ZM19.9898 11.6229C19.7234 10.2911 18.8578 9.22565 17.5259 8.62634C18.2584 7.96043 18.658 7.02816 18.658 5.96271C18.658 4.03158 17.193 2.5 15.3285 2.5C14.9289 2.5 14.6626 2.76636 14.6626 3.16591C14.6626 3.56545 14.9289 3.83181 15.3285 3.83181C16.4605 3.83181 17.3262 4.76408 17.3262 5.96271C17.3262 7.02816 16.7269 7.82725 15.7946 8.02702C15.4616 8.09361 15.1953 8.49316 15.3285 8.82611C15.3285 9.15906 15.5282 9.42542 15.8612 9.42542C17.3262 9.69179 18.325 10.5575 18.5914 11.8227C18.658 12.1556 18.9244 12.3554 19.2573 12.3554H19.3905C19.79 12.3554 20.0564 12.0225 19.9898 11.6229ZM4.60737 8.8927C4.74055 8.49316 4.47419 8.1602 4.14124 8.02702C3.27556 7.82725 2.67624 7.02816 2.67624 5.96271C2.74283 4.76408 3.54192 3.83181 4.67396 3.83181C5.07351 3.83181 5.33987 3.56545 5.33987 3.16591C5.33987 2.76636 5.00692 2.5 4.67396 2.5C2.80942 2.5 1.41102 3.96499 1.41102 5.96271C1.41102 7.02816 1.81057 7.96043 2.47647 8.62634C1.21125 9.22565 0.278981 10.2911 0.0126189 11.6229C-0.0539717 11.9559 0.1458 12.3554 0.545344 12.422H0.678525C1.01148 12.422 1.27784 12.2222 1.34443 11.8893C1.61079 10.6241 2.60965 9.75838 4.07465 9.49201C4.4076 9.49201 4.60737 9.22565 4.60737 8.8927Z"
        fill="#718096"
      />
    </svg>
  );
}
