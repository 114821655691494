import { ComponentPropsWithRef } from 'react';

export default function CommunityIcon({
  active,
  ...props
}: ComponentPropsWithRef<'svg'> & { active?: boolean }) {
  const color = active ? '#fff' : '#718096';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_4162_8637)">
        <path
          d="M8.35303 6.17995C8.35303 6.70508 8.56123 7.2087 8.93183 7.58002C9.30242 7.95134 9.80506 8.15995 10.3292 8.15995C10.8533 8.15995 11.3559 7.95134 11.7265 7.58002C12.0971 7.2087 12.3053 6.70508 12.3053 6.17995C12.3053 5.65482 12.0971 5.1512 11.7265 4.77988C11.3559 4.40856 10.8533 4.19995 10.3292 4.19995C9.80506 4.19995 9.30242 4.40856 8.93183 4.77988C8.56123 5.1512 8.35303 5.65482 8.35303 6.17995Z"
          fill={color}
        />
        <path
          d="M5.41895 12.6C5.41895 12.9023 5.53882 13.1923 5.75219 13.4061C5.96557 13.6199 6.25497 13.74 6.55672 13.74C6.85848 13.74 7.14788 13.6199 7.36125 13.4061C7.57463 13.1923 7.6945 12.9023 7.6945 12.6C7.6945 12.2976 7.57463 12.0077 7.36125 11.7939C7.14788 11.5801 6.85848 11.46 6.55672 11.46C6.25497 11.46 5.96557 11.5801 5.75219 11.7939C5.53882 12.0077 5.41895 12.2976 5.41895 12.6Z"
          fill={color}
        />
        <path
          d="M14.7603 18.18C14.7603 18.3869 14.8423 18.5853 14.9883 18.7316C15.1343 18.8778 15.3323 18.96 15.5387 18.96C15.7452 18.96 15.9432 18.8778 16.0892 18.7316C16.2352 18.5853 16.3172 18.3869 16.3172 18.18C16.3172 17.9732 16.2352 17.7748 16.0892 17.6285C15.9432 17.4822 15.7452 17.4 15.5387 17.4C15.3323 17.4 15.1343 17.4822 14.9883 17.6285C14.8423 17.7748 14.7603 17.9732 14.7603 18.18Z"
          fill={color}
        />
        <path
          d="M22.0063 5.52001C22.1261 5.34001 22.2459 5.16001 22.3656 4.98001C22.8447 4.08001 23.6232 2.70001 23.204 1.44001C22.9645 0.840008 22.5453 0.540008 22.2459 0.420008C21.168 0.0600083 19.7308 0.960008 19.4913 1.14001C19.1918 1.32001 19.132 1.68001 19.3116 1.98001C19.4913 2.28001 19.8505 2.34001 20.15 2.16001C20.629 1.86001 21.4674 1.44001 21.8866 1.62001C21.9465 1.62001 22.0063 1.68001 22.0662 1.86001C22.3058 2.58001 21.7069 3.66001 21.2877 4.44001C20.2697 6.30001 18.8924 7.98001 17.5151 9.72001C14.2814 13.68 10.5687 17.04 6.13733 19.92C5.95768 20.04 5.77803 20.16 5.5385 20.28C3.14318 18.36 1.58622 15.36 1.58622 12C1.58622 6.12001 6.31698 1.38001 12.1855 1.38001C13.5029 1.38001 14.7006 1.62001 15.8384 2.04001C15.8983 2.04001 15.9582 2.10001 16.018 2.10001C16.3773 2.10001 16.6169 1.86001 16.6169 1.50001C16.6169 1.26001 16.4372 1.02001 16.1977 0.960008C14.9401 0.480008 13.5628 0.240008 12.1256 0.240008C5.65827 0.180008 0.388561 5.46001 0.388561 12C0.388561 14.04 0.927508 15.96 1.82575 17.64C1.58622 18 0.747859 19.14 0.208912 20.46C-0.150386 21.3 -0.0306202 22.08 0.448444 22.62C0.807742 23.04 1.40657 23.28 2.06529 23.28C2.30482 23.28 2.60423 23.22 2.84377 23.16C3.80189 22.86 4.70014 22.32 5.47862 21.78C7.39488 23.1 9.67043 23.88 12.1855 23.88C18.7128 23.82 23.9825 18.54 23.9825 12C23.9825 9.60001 23.2639 7.38001 22.0063 5.52001ZM2.48447 21.96C2.06529 22.08 1.58622 22.02 1.34669 21.78C1.10716 21.54 1.22692 21.18 1.28681 20.88C1.6461 20.04 2.12517 19.2 2.48447 18.72C3.0833 19.56 3.74201 20.28 4.46061 20.94C3.86178 21.36 3.20306 21.72 2.48447 21.96ZM12.1855 22.62C10.1495 22.62 8.23324 22.02 6.6164 21C6.67628 20.94 6.73616 20.94 6.79605 20.88C11.2873 17.94 15.1198 14.52 18.4134 10.44C19.4314 9.18001 20.3895 7.98001 21.2877 6.66001C22.186 8.22001 22.7848 10.08 22.7848 12C22.7848 17.88 18.0541 22.62 12.1855 22.62Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4162_8637">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
